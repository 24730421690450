<template>
<v-app id="inspire">
	<v-main>
		<v-container fluid fill-height>
			<v-layout align-center justify-center>
				<v-flex xs12 sm8 md4>
					<v-card class="elevation-1">
						<v-toolbar dark flat color="primary">
							<v-toolbar-title>Login Panel ({{ $store.state.version }})</v-toolbar-title>
						</v-toolbar>
						<v-card-text>
							<v-form v-model="stValido">
								<v-text-field
									v-model="usuario"
									prepend-icon="mdi-account"
									name="login"
									label="Login"
									type="text"
									:rules="reglas"
								></v-text-field>
								<v-text-field
									v-model="clave"
									prepend-icon="mdi-lock"
									name="password"
									label="Password"
									type="password"
									:rules="reglas"
								></v-text-field>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn :disabled="!stValido" color="primary" large @click="fnLogin">Login</v-btn>
						</v-card-actions>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</v-main>
	</v-app>
</template>

<script>
export default {
	name: 'Login',
	metaInfo: {
		title: 'Login'
	},
	data() {
		return {
			stValido: false,
			usuario: '',
			clave: '',
			reglas: [
				value => !!value || 'Required.'
			]
		}
	},
	mounted() {},
	methods: {
		fnLogin() {
			let usuario = this.usuario
			let clave = this.clave
			this.$store.dispatch('login', { user: usuario, pass: clave })
				.then(respuesta => {
					if(!respuesta) {
						this.$store.state.estado = 'red darken-4'
						this.$store.state.mensaje = 'Error: check the user or the password!'
						this.$store.state.snackme = true
						this.$errorsnd()
					}else{
						window.location.href = "/"
					}
				})
		}
	}
};
</script>

<style>
.login-form {
max-width: 500px;
}
</style>